import React from 'react';
import NavBar from './nav';
import Footer from './footer'
const Layout = ({ children }) => {
    return (
        <>
        <div>
            <NavBar/>
            <main>{children}</main>
            <Footer/>
        </div>
    </> );
}
 
export default Layout;
import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <>
      <div>
        <footer className="footer-area">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-sm-6 col-md-6 col-xl-3">
                <div className="single-footer-widget footer_1">
                  <a href="/">
                    {" "}
                    <img src="/img/logoti.png" alt="" />{" "}
                  </a>
                  <p>
                    Our services range from design to market, digital solutions
                    and business process improvement
                  </p>
                  <p>We are passionate about solving business problems.</p>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-xl-4">
                <div className="single-footer-widget footer_2">
                  <ul>
                    <li>
                      <a
                        className="nav-link"
                        href="https://twitter.com/papertentech"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Twitter
                      </a>
                    </li>
                    <li>
                      <Link className="nav-link" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/contact">
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/career">
                        Career
                      </Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/services">
                        Services
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-12 col-md-8 col-xl-3">
                <div className="single-footer-widget footer_3">
                  <h4>Instagram</h4>
                  <div className="footer_img">
                    <div className="single_footer_img">
                      <img src="img/footer_img/footer_img_1.png" alt="" />
                      <a href="/">
                        <i className="ti-instagram"></i>
                      </a>
                    </div>
                    <div className="single_footer_img">
                      <img src="img/footer_img/footer_img_2.png" alt="" />
                      <a href="/">
                        <i className="ti-instagram"></i>
                      </a>
                    </div>
                    <div className="single_footer_img">
                      <img src="img/footer_img/footer_img_3.png" alt="" />
                      <a href="/">
                        <i className="ti-instagram"></i>
                      </a>
                    </div>
                    <div className="single_footer_img">
                      <img src="img/footer_img/footer_img_4.png" alt="" />
                      <a href="/">
                        <i className="ti-instagram"></i>
                      </a>
                    </div>
                    <div className="single_footer_img">
                      <img src="img/footer_img/footer_img_5.png" alt="" />
                      <a href="/">
                        <i className="ti-instagram"></i>
                      </a>
                    </div>
                    <div className="single_footer_img">
                      <img src="img/footer_img/footer_img_6.png" alt="" />
                      <a href="/">
                        <i className="ti-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright_part_text text-center">
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="footer-text m-0">
                        Copyright &copy; 2009-2021 All rights reserved
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Footer
